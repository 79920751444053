import React, { useState,useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ref, onValue, set, push, child, update,remove } from 'firebase/database';
import { db } from '../firebase';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
const ThreeDotMenu = ({id}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentEntryDBPath,setCurrentEntryDBPath] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleClick = (event) => {
    setCurrentEntryDBPath(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setSnackbarOpen(false);
};
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = (event)=>{
    handleClose();
  }
  const handleDelete = (event)=>{
    handleClose();
    setOpenDialog(true);
  }
  const handleConfirmDelete = () => {
    const entryRef = ref(db,currentEntryDBPath);
    remove(entryRef).then(()=>{
      setSnackbarMessage("Entry deleted successfully.")
      setSnackbarOpen(true);
    }).catch((err)=>{
      console.log(err);
      setSnackbarMessage("Failed to delete the entry.")
      setSnackbarOpen(true);
    })
  
    setOpenDialog(false);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="three-dot-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{color:"white"}}
        id={id}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="three-dot-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}><EditIcon sx={{ marginRight: 1 }} /> Edit</MenuItem>
        <MenuItem onClick={handleDelete}> <DeleteIcon sx={{ marginRight: 1 }} /> Delete</MenuItem>

      </Menu>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this entry?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} sx={{color:"red"}} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>    
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      //   action={action}
      />
    </div>
  );
};

function TableCaption({ children }) {
  return (
    <Typography variant="h6" align="center" color="secondary" gutterBottom>
      {children}
    </Typography>
  );
}
function ClientTable({ salesData }) {
  useEffect(() => {
    if (salesData) {
      salesData.map((dayData) => {
        const dateString = dayData.date;
        const parts = dateString.split('/');
        const month = parseInt(parts[0]);
        const day = parseInt(parts[1]);
        const year = parseInt(parts[2]);
        const path = `sales/${year}/${month}/${day}/${dayData.key}`;
        dayData.dbPath = path
        // ref(db,path).remove()
        // console.log(path);
      })
    }
    // console.log(salesData);
  }, [salesData])
  function formatDate(dateString) {
    const parts = dateString.split('/');
    const month = parseInt(parts[0]);
    const day = parseInt(parts[1]);
    const year = parseInt(parts[2]);

    // Create a Date object from the parsed components
    const date = new Date(year, month - 1, day);

    // Format the date as desired (e.g., "April 4, 2024")
    const options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }
  return (
    <TableContainer component={Paper} sx={{ backgroundColor: "black", maxHeight: 330,width:"100%" }}>
      <Divider color='white'></Divider>
      <Table stickyHeader aria-label="simple table">
        <TableHead >
          <TableRow >
            <TableCell sx={{backgroundColor:"black"}} align="center" ><Typography variant='h7' color={"secondary"}>Date</Typography></TableCell>
            <TableCell sx={{backgroundColor:"black"}} align="center" ><Typography variant='h7' color={"secondary"}>Type</Typography></TableCell>
            <TableCell sx={{backgroundColor:"black"}} align="center" ><Typography variant='h7' color={"secondary"}>Society</Typography></TableCell>
            <TableCell sx={{backgroundColor:"black"}} align="center" ><Typography variant='h7' color={"secondary"}>Services</Typography></TableCell>
            <TableCell sx={{backgroundColor:"black"}} align="center" ><Typography variant='h7' color={"secondary"}>Bill</Typography></TableCell>
            <TableCell sx={{backgroundColor:"black"}} align="center" ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {salesData && salesData.map((row) => (
            <TableRow key={row.date}>
              <TableCell component="th" scope="row" align="center" sx={{color:"white"}}>
                {formatDate(row.date)}
              </TableCell>
              <TableCell align="center" sx={{color:"white"}}>{row.type}</TableCell>
              <TableCell align="center" sx={{color:"white"}}>{row.society}</TableCell>
              <TableCell align="center" sx={{color:"white"}}>{row.services.join(' , ').toString()}</TableCell>
              <TableCell align="center" sx={{color:"white"}}>{`₹ ${row.bill}`}</TableCell>
              <TableCell align="center" sx={{color:"white"}}>{<ThreeDotMenu id={row.dbPath} />}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

}

export default ClientTable