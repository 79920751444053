import React from 'react';
import {Box,Typography} from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

const ImageCarousel = ({ images }) => {
  return (
    <Slider {...settings}>
      {images.map((item, index) => (
        <Box key={index}>
          <Typography variant="subtitle1" align="center" color="white" mt={1}>
              {item.caption}
            </Typography>
          <img src={item.image} alt={`Slide ${index + 1}`} style={{ width: '100%', maxHeight: '500px', height: 'auto', objectFit: 'contain' }} />
        </Box>
      ))}
    </Slider>
  );
};

export default ImageCarousel;
