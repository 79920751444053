import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#000000',
        color: '#ffffff',
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <Typography variant="body2">
        © 2024 Olivia Salon. All rights reserved.
        <br />
        Contact: <Link href="tel:+918421012595" color="inherit">+918421012595</Link>
        <br />
        Shop No 11, Kaizen Society, Sopan Baug Rd, near F Residences, Balewadi, Pune, Maharashtra 411045
        <br />
        Follow us: 
        {/* <IconButton size="small" component={Link} href="https://www.facebook.com/" target="_blank" rel="noopener" aria-label="Facebook">
          <Facebook />
        </IconButton> */}
        <IconButton  size="small" component={Link} href="https://www.instagram.com/olivia.salon16/" target="_blank" rel="noopener" aria-label="Instagram">
          <Instagram color='secondary' />
        </IconButton>
        {/* <IconButton size="small" component={Link} href="https://twitter.com/" target="_blank" rel="noopener" aria-label="Twitter">
          <Twitter />
        </IconButton> */}
      </Typography>
    </Box>
  );
};

export default Footer;
