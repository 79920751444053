import { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Home from './components/Home';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import BasicAppBar from './components/BasicAppBar';
import Box from '@mui/material/Box';
import Login, {toggleSignIn} from './components/Login';
import Dashboard from './components/Dashboard';
import { auth } from './firebase';
import ProfilePage from './components/ProfilePage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#e7348e',
    },
  },
});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
        setUser(user);
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Box sx={{
          overflow: "auto",
          height: "100vh",
          backgroundColor: "#000000"
        }}>
          <BasicAppBar height={"10vh"} title={"  Olivia Salon"} bgColor={"primary"} photoUrl={user ? user.photoURL : ""} displayName={user ? user.displayName : ""} toggleSignIn={toggleSignIn} setIsAuthenticated={setIsAuthenticated} />
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} /> */}
            <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />} />
            <Route path="/profile" element={user?<ProfilePage user = {user} />:<Navigate to="/" />}></Route>
          </Routes>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
