// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    DatabaseReference,
    Query,
    child,
    connectDatabaseEmulator,
    getDatabase,
    limitToLast,
    off,
    onChildAdded,
    onChildChanged,
    onChildRemoved,
    onValue,
    orderByChild,
    push,
    query,
    ref,
    runTransaction,
    set,
    update,
  } from 'firebase/database';
  import {getAuth} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC-Djs509xmYHtqyU8Uy8F9fRuBYI0iKhU",
  authDomain: "oliviasalon.in",
  databaseURL: "https://olivia-salon-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "olivia-salon",
  storageBucket: "olivia-salon.appspot.com",
  messagingSenderId: "830130061879",
  appId: "1:830130061879:web:3842ddf868b5ffb23ff5b2",
  measurementId: "G-0JHTVYT8H0"
};

// Initialize Firebase
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth();
export const db = getDatabase();

// export default firebase;
// export app as firebase