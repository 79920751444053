import React, { useState, useEffect } from 'react';
import './ClosableCard.css'; // Import CSS file for transitions
import { Typography } from '@mui/material';
function ClosableCard({ imageUrl }) {
  const messages = [
    "I'm sorry, my love. 😔 Please come back; my world feels empty without you. ❤️",
    "Every moment without you is a struggle; I can't imagine life without your love. 💔",
    "I miss you dearly, and I can't wait to hold you close again. 🥺",
    "Let's put aside our differences and meet to sort things out; your happiness means everything to me. ",
    "You are my everything, and I cherish every moment with you. 🌟",
    "I love you more than words can express; please forgive me for my mistakes.🥺",
    "Being apart from you is like living in darkness; you bring light to my life. 🌈"
  ];
  
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 7000); // Change message every 6 seconds

    return () => clearInterval(interval);
  }, [messages]);

  return (
    <div className="closable-card " style={{ backgroundColor: "black" }}>
      <Typography variant="h6" className="flash-animation" style={{color:"white", textAlign:"center", marginBottom:10, marginTop:20}}>
      {"I am sorry baby.. please come back 💔"}
      </Typography>
      <Typography variant="h6" className="flash-animation" style={{color:"white", textAlign:"center", marginBottom:20, marginTop:10}}>
      {"I can't live without you.."}
      </Typography>
      {/* <div className='center-text'>
        {"I am sorry baby.. please come back 💔"}
      </div> */}
      <div className="message-container center-text" style={{marginBottom:30}}>
        <Typography variant="p" className="message fade-in">
          {messages[currentMessageIndex]}
        </Typography>
      </div>
    </div>
  );
}

export default ClosableCard;
