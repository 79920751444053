import React, { useEffect, useState } from 'react'
import { FormHelperText, Paper, InputAdornment, Box, SpeedDial, SpeedDialAction, Backdrop, Chip, Autocomplete, Grid, TextField, Button, Checkbox, Radio, FormControlLabel, FormControl, FormLabel, RadioGroup } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { db } from '../firebase';
import { ref, onValue, set, push, child, update } from 'firebase/database';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { availableServices,societyOptions } from '../utils/constants';
// import { makeStyles } from '@mui/system';
const defaultFormData = {
    date: new Date(),
    customerType: "New",
    selectedSociety: { label: "", value: "" },
    availedServices: [],
    attendant: "",
    paymentType: "Online",
    bill: "",
    customerName: ""
    // availedServices:null
}

function EntryForm({ onModalClose }) {
    // const classes = useStyles();
    const [formData, setFormData] = useState(defaultFormData);
    const [filteredServices, setFilteredServices] = useState(availableServices);
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    // Filter out services that are already selected
    useEffect(() => {
        const filteredServicesList = availableServices.filter(
            (service) => !formData.availedServices.find((selectedService) => selectedService.value === service.value)
        );
        setFilteredServices(filteredServicesList);
    }, [formData.availedServices])


    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {
            let formatedFormData = formatFormData(formData);
            const date = formatedFormData.date;
            const dateParts = date.split('/');
            const year = dateParts[2];
            const month = dateParts[0];
            const day = dateParts[1];
            const dbEntryPath = `sales/${year}/${month}/${day}`;
            const newDataEntryKey = push(child(ref(db), dbEntryPath)).key;
            const updates = {};
            updates[`${dbEntryPath}/${newDataEntryKey}`] = formatedFormData;
            update(ref(db), updates).then(() => {
                resetFormData();
                setSnackbarMessage("Entry added successfully.")
                setSnackbarOpen(true);
            }).catch((err) => {
                setSnackbarMessage("Failed to add an entry.")
                setSnackbarOpen(true);
                console.log(err);
            })
        } else {
            //form is invalid, set errors
            setErrors(validationErrors);
        }

    }
    const handleCustomerTypeChange = (event) => {
        setFormData({ ...formData, customerType: event.target.value })
    }
    const handlePaymentTypeChange = (event) => {
        setFormData({ ...formData, paymentType: event.target.value })
    }
    const handleSocietyChange = (event, newValue) => {
        setFormData({ ...formData, selectedSociety: newValue });
    }
    const handleAvailedServiceChange = (event, newValue) => {
        setFormData({ ...formData, availedServices: newValue });
    }

    function formatDate(date) {
        const month = String(date.getMonth() + 1);//.padStart(2, '0');
        const day = String(date.getDate());//.padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    }
    const handleDateChange = (event) => {
        const month = event.month();
        const day = event.date();
        const year = event.year();
        const date = new Date(year, month, day);
        // const dateString = formatDate(date);
        setFormData({ ...formData, date: date });
    }
    const handleAttendantNameChange = (event) => {
        setFormData({ ...formData, attendant: event.target.value });
    }
    const handlebillAmountChange = (event) => {
        const value = event.target.value;

        // Regular expression to allow positive float numbers
        const regex = /^\d*\.?\d*$/;

        // Check if the input matches the regex and it's not empty
        if (value === '' || regex.test(value)) {
            setFormData({ ...formData, bill: value });
        }
    }
    const handleCustomerNameChange = (event) => {
        setFormData({ ...formData, customerName: event.target.value });
    }
    const formatFormData = (formData) => {
        let formatedData = {};
        const timeStamp = Date.now();
        formatedData["attendant"] = formData.attendant;
        formatedData["society"] = formData.selectedSociety.value;
        formatedData["type"] = formData.customerType;
        let availedServicesArray = [];
        formData.availedServices.map((serviceObject) => {
            availedServicesArray.push(serviceObject.label)
        });
        formatedData["services"] = availedServicesArray;
        formatedData["date"] = formatDate(formData.date);
        formatedData["bill"] = parseFloat(formData.bill);
        formatedData["timestamp"] = getFormattedTimestamp();
        formatedData["customerName"] = formData.customerName;
        return formatedData;
    }
    const getFormattedTimestamp = () => {
        const now = new Date();
        const day = now.getDate();
        const month = now.getMonth() + 1; // January is 0, so we add 1 to get correct month
        const year = now.getFullYear();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();

        // Formatting the values to ensure they have two digits
        const formattedDay = String(day).padStart(2, '0');
        const formattedMonth = String(month).padStart(2, '0');
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        // Constructing the formatted timestamp string
        const timestamp = `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

        return timestamp;
    }
    const resetFormData = () => {
        setFormData({ ...defaultFormData, date: formData.date });
    }
    const validateForm = () => {
        let errors = {};
        if (!formData.attendant.trim()) {
            errors.attendant = "Please enter attendant's name.";
        }
        if (!formData.bill.trim()) {
            errors.bill = "Please enter a bill amount."
        }
        if (formData.availedServices.length === 0) {
            errors.services = "Please select availed services."
        }
        if (!formData.selectedSociety.label) {
            errors.society = "Please select a society."
        }
        return errors;
    };
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    return (
        <form onSubmit={handleSubmit} style={{overflowX:"hidden"}}>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                anchorOrigin={ {vertical:"top", horizontal:"center"}}
            //   action={action}
            />
            <Grid container spacing={1} margin={1}>
                <Grid item xs={12} sx={{ marginRight: 5, marginBottom: 1, marginLeft: 5 }}>
                    <FormControl sx={{ width: "80%" }} required={true}>
                        <FormLabel id="select-date-label">Select Date</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker defaultValue={dayjs()} label="" onChange={handleDateChange} />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ marginRight: 5, marginBottom: 1, marginLeft: 5 }}>
                    <FormControl sx={{ width: "80%" }} required={true}>
                        <FormLabel id="select-customer-type-label">Customer Type</FormLabel>
                        <RadioGroup aria-labelledby="select-customer-type-label" defaultValue={{ label: "New", value: "New" }} name="radio-buttons-group" value={formData.customerType} onChange={handleCustomerTypeChange}>
                            <FormControlLabel value={"New"} control={<Radio />} label="New" />
                            <FormControlLabel value={"Repeat"} control={<Radio />} label="Repeat" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ marginRight: 5, marginBottom: 1, marginLeft: 5 }}>
                    <FormControl sx={{ width: "80%" }} required={true} error={Boolean(errors.society)}>
                        <FormLabel id="select-society-label">Select Society</FormLabel>
                        <Autocomplete
                            options={societyOptions}
                            getOptionLabel={(option) => option.label}
                            value={formData.selectedSociety}
                            onChange={handleSocietyChange}
                            renderInput={(params) => <TextField {...params} label="" />}
                            fullWidth={true}
                        />
                        <FormHelperText>{errors.society}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ marginRight: 5, marginBottom: 1, marginLeft: 5 }}>
                    <FormControl sx={{ width: "80%" }} required={false} >
                        <FormLabel id="customer-name-label">Customer Name</FormLabel>
                        <TextField label="" id="customer-name-textfield" value={formData.customerName} onChange={handleCustomerNameChange}></TextField>
                    </FormControl>
                </Grid>
                <Grid xs={12} sx={{ marginRight: 5, marginBottom: 1, marginLeft: 5 }}>
                    <FormControl sx={{ width: "80%" }} required={true} error={Boolean(errors.services)}>
                        <FormLabel id="select-availed-services-label">Select Availed Service</FormLabel>
                        <Autocomplete
                            multiple
                            options={filteredServices}
                            getOptionLabel={(option) => option.label}
                            value={formData.availedServices}
                            onChange={handleAvailedServiceChange}
                            renderInput={(params) => <TextField {...params} label="" />}
                            PaperComponent={({ children }) => (
                                <Paper sx={{ height: 94, overflow: 'auto', }}>{children}</Paper>
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={index}
                                        label={option.label}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            sx={{ maxHeight: 100, overflow: "auto" }}
                        />
                        <FormHelperText>{errors.services}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ marginRight: 5, marginBottom: 1, marginLeft: 5 }}>
                    <FormControl sx={{ width: "80%" }} required={true} error={Boolean(errors.attendant)}>
                        <FormLabel id="select-attendant-label">Attendant's Name</FormLabel>
                        <TextField label="" id="attendant-name-textfield" value={formData.attendant} onChange={handleAttendantNameChange}></TextField>
                        <FormHelperText>{errors.attendant}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ marginRight: 5, marginBottom: 1, marginLeft: 5 }}>
                    <FormControl sx={{ width: "80%" }} required={true}>
                        <FormLabel id="payment-method-label">Payment Method</FormLabel>
                        <RadioGroup aria-labelledby="payment-method-label" defaultValue={{ label: "Online", value: "Online" }} name="radio-buttons-group" value={formData.paymentType} onChange={handlePaymentTypeChange}>
                            <FormControlLabel value={"Online"} control={<Radio />} label="Online" />
                            <FormControlLabel value={"Cash"} control={<Radio />} label="Cash" />
                        </RadioGroup>

                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ marginRight: 5, marginBottom: 2, marginLeft: 5 }}>
                    <FormControl sx={{ width: "80%" }} required={true} error={Boolean(errors.bill)}>
                        <FormLabel id="bill-amount-label">Bill Amount</FormLabel>
                        <TextField InputProps={{
                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        }} id="bill-amount-textfield" value={formData.bill} onChange={handlebillAmountChange}></TextField>
                        <FormHelperText>{errors.bill}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={10}>
                </Grid>
                <Grid item >
                    <Button type="submit" variant="contained" color='secondary'>Save</Button>
                </Grid>
                <Grid item sx={{ marginRight: 0 }}>
                    <Button variant="contained" color='primary' onClick={() => {
                        onModalClose();
                    }}>Close</Button>
                </Grid>
            </Grid>

        </form>


    )
}

export default EntryForm