import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, Typography, Button, Box, Divider } from '@mui/material';
import salonImage1 from '../res/interior1.jpg';
import salonImage2 from '../res/interior2.jpg';
import salonImage3 from '../res/interior3.jpg';
import hairwashImage from '../res/hairwash.jpg';
import eyebrowThreading from '../res/eyebrowThreading.jpg';
import facial from '../res/facial.jpg';
import hairCut from '../res/hair-cut.jpg';
import ImageCarousel from './ImageCarousel';
import backgroundImage from '../res/background.jpg';
import Footer from './Footer';
import TestimonialCarousel from './TestimonialCarousel';
const salonImages = [{image:salonImage1, caption:""}, {image:salonImage2, caption:""}, {image:salonImage3, caption:""}];
const serviceImages = [{image:hairwashImage, caption:"Hair wash"},
 {image:eyebrowThreading,caption:"Threading"},
  {image:facial, caption:"Facial"},
  {
    image:hairCut, caption:"Hair cut"
  }
];

const testimonials = [
  {
    author: 'Pooja Kenjale',
    text: 'Awesome experience!! I opted for hair treatment called Bluetox. The service was up to mark and exactly as I expected. And the best thing is the owner, she is so genuine, polite and friendly that i am sure this will be my permanent go to Salon henceforth.. keep up the great work that you are doing ❤️❤️',
    rating: 5,
    avatar: 'https://example.com/avatar1.jpg',
  },
  {
    author: 'Preeti Lohbande',
    text: 'It was wonderful experience to visit this salon, it was my first visit.. went for threading and waxing, it was just awsome.. Please do visit this salon for a splendid experience. And the owner is very polite and well experienced..',
    rating: 4.5,
    avatar: 'https://example.com/avatar2.jpg',
  },
  {
    author: 'Mrunal Dahiphale',
    text: 'Well organized and clean salon. I got the best hair color treatment',
    rating: 5,
    avatar: 'https://example.com/avatar2.jpg',
  },
  {
    author: 'Swati Patil',
    text: 'It was my first visit to Olivia salon, I did waxing and threading.Both the girls were very polite and knew their job well. Highly recommended 👍🏻 Will visit again 😊',
    rating: 4.5,
    avatar: 'https://example.com/avatar2.jpg',
  },
  // Add more testimonials as needed
];
const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Black color as primary
    },
    secondary: {
      main: '#e7348e', // Custom color
    },
    background: {
      default: '#000000', // Black color for background
    },
  },
});

const Home = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ minHeight: '100vh',
    }}>
        <Container
          maxWidth="md"
          sx={{
            marginTop: 4,
            marginBottom: 8,
            textAlign: 'center',
            color: '#ffffff', // Set text color to white
            position: 'relative',
            zIndex: '1',
          }}
        >
          {/* <Typography variant="h1" component="h1" color="white" gutterBottom>
            Olivia Salon
          </Typography> */}
          <Typography variant="h5" color="lightgrey" paragraph>
            Transforming Beauty, Empowering You
          </Typography>
          <Box position="relative" zIndex="1">
            <ImageCarousel images={salonImages}></ImageCarousel>
          </Box>
          <Divider sx={{ mt: 4, mb: 2,backgroundColor: '#ffffff' }} /> {/* Divider between salon images and services */}
          <Typography variant="h4" component="h2" color="white" gutterBottom mt={2}>
            Our Services
          </Typography>
          <Box position="relative" zIndex="1" mt={2}>
            <ImageCarousel images={serviceImages}></ImageCarousel>
          </Box>
          {/* <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ marginTop: 4 }}
          >
            Book Appointment
          </Button> */}
          <Divider sx={{ mt: 4, mb: 2,backgroundColor: '#ffffff'}} /> {/* Divider between services and button */}
          <Typography variant="h4" component="h2" color="white" gutterBottom mt={2}>
            Testimonials
          </Typography>
        <TestimonialCarousel testimonials={testimonials}></TestimonialCarousel>  
        </Container>
        <Divider sx={{ mt: 4, mb: 2, backgroundColor: '#ffffff' }} /> {/* Divider between services and button */}
          
        <Footer></Footer>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
