import React, { useEffect, useState, useRef } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import moment from 'moment';
const RevenueChart = ({ data, height }) => {
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);
  const chartRef = useRef(null);
  const [width, setWidth] = useState(0);
  const pinkColor = "#e7348e";
  useEffect(() => {
    const updateWidth = () => {
      if (chartRef.current) {
        setWidth(chartRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    if (data) {
      const revenueList = [];
      const weekNameList = [];

      data.forEach(datapoint => {
        weekNameList.push(formatDate(datapoint.week));
        revenueList.push(datapoint.revenue);
      });

      setXAxisData(weekNameList);
      setYAxisData(revenueList);
    }
  }, [data]);
    const formatDate = (dateString) => {
    // Parse the input date string using moment.js
    const date = moment(dateString, 'MM/DD/YYYY');
    
    // Format the date as "dd month year"
    const formattedDate = date.format('DD MMMM YYYY');
    
    return formattedDate;
  };
  return (
    <div ref={chartRef} style={{ width: '100%' }}>
      <BarChart
        width={width}
        height={height}
        series={[
          {
            data: yAxisData,
            label: 'Revenue',
            id: 'revenueId',
            color: pinkColor // Bar color
          },
        ]}
        xAxis={[
          {
            data: xAxisData,
            scaleType: 'band',
            axisLine: { stroke: 'white' }, // X-axis color
            tick: { fill: 'white' }, // X-axis label color
            categoryGapRatio: 0.6
          }
        ]}
        yAxis={[
          {
            axisLine: { stroke: 'white' }, // Y-axis color
            tick: { fill: 'white' }, // Y-axis label color
          }
        ]}
        style={{ color: pinkColor }} // Bar label color
        sx={{
            //change left yAxis label styles
           "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel":{
            strokeWidth:"0.4",
            fill:"#ffffff"
           },
           // change all labels fontFamily shown on both xAxis and yAxis
           "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel":{
               fontFamily: "Roboto",
               fill:"#FFFFFF",
               strokeWidth:"0.5",
            },
            // change bottom label styles
            "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel":{
                strokeWidth:"0.5",
                fill:"#FFFFFF"
             },
              // bottomAxis Line Styles
             "& .MuiChartsAxis-bottom .MuiChartsAxis-line":{
              stroke:"#FFFFFF",
              strokeWidth:0.4
             },
             // leftAxis Line Styles
             "& .MuiChartsAxis-left .MuiChartsAxis-line":{
              stroke:"#FFFFFF",
              strokeWidth:0.4
             },
             "& .MuiChartsAxis-tick .MuiChartsAxis-label":{
                stroke:"#FFFFFF",
                strokeWidth:0.4
               }
          }}
      />
    </div>
  );
};

export default RevenueChart;
