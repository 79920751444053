import React from 'react';
import { Box, Typography, Avatar, Rating } from '@mui/material';

const Testimonial = ({ author, text, rating, avatar }) => {
  return (
    <Box>
      {/* <Avatar src={avatar} sx={{ width: 64, height: 64, marginBottom: '16px' }} /> */}
      <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: '16px' }}>
        "{text}"
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
        - {author}
      </Typography>
      <Rating value={rating} precision={0.5} readOnly />
    </Box>
  );
};

export default Testimonial;
