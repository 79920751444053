import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, Box } from '@mui/material';
import { updateProfile  } from 'firebase/auth'; // Update this import based on your firebase setup
import { useNavigate } from 'react-router-dom';
export default function ProfilePage({ user }) {
    const [displayName, setDisplayName] = useState(user.displayName || '');
    // const [photoURL, setPhotoURL] = useState(user.photoURL || '');

    const handleSave = () => {
        updateProfile(user, { displayName });
    };
 
    return (
        <Box sx={{ maxWidth: 400, margin: 'auto', textAlign: 'center',backgroundColor:"white" }}>
            <Typography variant="h4" gutterBottom>
                Profile
            </Typography>
            <TextField
                label="Display Name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                fullWidth
                margin="normal"
            />
            {/* <TextField
                label="Profile Picture URL"
                value={photoURL}
                onChange={(e) => setPhotoURL(e.target.value)}
                fullWidth
                margin="normal"
            /> */}
            <Button variant="contained" color="primary" onClick={handleSave}>
                Save
            </Button>
        </Box>
    );
}
