import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { Menu, MenuItem, Divider, ListItemIcon, Modal, TextField } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import logoHighRes from '../res/logoHighRes.PNG';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { SpaceDashboard } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {signInWithEmailAndPassword} from 'firebase/auth';
// import ProfilePage from './ProfilePage';
import { styled } from '@mui/system';
// import {FormContainer,} from '@mui/material'

const FormContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2rem',
    backgroundColor: 'white', // Set background color to white
    padding: '2rem', // Add padding for better appearance
});

const Form = styled('form')({
    width: '100%',
    maxWidth: 400,
    marginTop: '1rem',
    marginBottom: '2rem',
});

const SubmitButton = styled(Button)({
    margin: '1rem 0 2rem',
});

const ErrorText = styled(Typography)({
    color: 'red',
    textAlign: 'center',
    marginTop: '1rem',
});
export default function BasicAppBar({ title, bgColor }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    const handleSignIn = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setModalOpen(false);
        } catch (error) {
            setError("Sign in failed.");
        }
    };
    const handleAvatarMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogOut = () => {
        signOut(auth);
        setAnchorEl(null);
        setPassword('');
        setEmail('');
        navigate('/');
    };

    const handleDashboardClick = () => {
        navigate('/dashboard');
        setAnchorEl(null);
    };

    const handleProfileClick = () => {
        navigate('/profile');
        setAnchorEl(null);
    };

    const handleHomeClick = () => {
        navigate('/');
    };

    const handleLoginButtonClick = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color={bgColor}>
                <Toolbar>
                    <img height={'40px'} src={logoHighRes} alt="Logo" />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 2 }}>
                        {title}
                    </Typography>
                    <Button color="inherit" sx={{ marginLeft: 2 }} onClick={handleHomeClick}>
                        Home
                    </Button>
                    {user ? (
                        <>
                            <IconButton
                                onClick={handleAvatarClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={anchorEl ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={anchorEl ? 'true' : undefined}
                            >
                                <Avatar alt={user.displayName}>{user.displayName?user.displayName.split(' ')[0].charAt(0):''}</Avatar>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={Boolean(anchorEl)}
                                onClose={handleAvatarMenuClose}
                                onClick={handleAvatarMenuClose}
                            >
                                <MenuItem onClick={handleDashboardClick}>
                                    <SpaceDashboard />
                                    &nbsp;&nbsp;&nbsp;Dashboard
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleProfileClick}>
                                    <ListItemIcon>
                                        <SettingsIcon fontSize="small" />
                                    </ListItemIcon>
                                    Profile
                                </MenuItem>
                                <MenuItem onClick={handleLogOut}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <Button color="inherit" sx={{ right: 0 }} onClick={handleLoginButtonClick}>
                            Login
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                  <FormContainer>
                  <img src={logoHighRes}></img>
                    <Typography component="h1" variant="h5">
                        Welcome back!
                    </Typography>
                    <Form noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {error && <ErrorText>{error}</ErrorText>}
                        <SubmitButton
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSignIn}
                        >
                            Sign In
                        </SubmitButton>
                    </Form>
                </FormContainer>
            </Modal>
        </Box>
    );
}
