import React, { useEffect, useState } from 'react'
// import firebase from 'firebase/app';
import { auth } from '../firebase';
import { GoogleAuthProvider, signInWithEmailAndPassword,EmailAuthProvider,sign,onAuthStateChanged,getRedirectResult,signInWithRedirect,signOut } from 'firebase/auth'
import 'firebaseui/dist/firebaseui.css'; // Import FirebaseUI CSS
// import * as firebaseui from 'firebaseui';
import {Grid,Button, SvgIcon} from '@mui/material';

const buttonStyles = {
  display: 'inline-block',
  backgroundColor: '#fff',
  color: '#757575',
  border: '1px solid #ccc',
  borderRadius: '4px',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};
const hoverStyles = {
  backgroundColor: '#f2f2f2',
};
export const toggleSignIn = ()=> {
  if (!auth.currentUser) {
    // const provider = new GoogleAuthProvider();
    // provider.addScope('https://www.googleapis.com/auth/plus.login');
    // signInWithRedirect(auth, provider);
    const provider = new EmailAuthProvider();
    signInWithEmailAndPassword(auth)
  } else {
    signOut(auth);
  }
  // signInButton.disabled = true;
}
function Login({ setIsAuthenticated }) {
 
  useEffect(() => {
    getRedirectResult(auth)
  .then(function (result) {
    if (!result) return;
    const credential = GoogleAuthProvider.credentialFromResult(result);
    if (credential) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const token = credential?.accessToken;
      // oauthToken.textContent = token ?? '';
    } else {
      // oauthToken.textContent = 'null';
    }
    // The signed-in user info.
    const user = result.user;
    setIsAuthenticated(true)
  })
  .catch(function (error) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    const credential = error.credential;
    if (errorCode === 'auth/account-exists-with-different-credential') {
      alert(
        'You have already signed up with a different auth provider for that email.',
      );
      // If you are using multiple auth providers on your app you should handle linking
      // the user's accounts here.
    } else {
      console.error(error);
    }
  });
  }, [])
  return (
    <>
    </>
    // <Grid container justifyContent={"center"} alignItems={"center"}>
    //   {/* <Grid item xs={12}>
    //     Welcome Back!
    //   </Grid> */}
    //   <Grid item>
    //     <Button  variant="contained" onClick={toggleSignIn} style={buttonStyles}
    //     // onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
    //     > Continue with Google</Button>
    //   </Grid>
    // </Grid>
  )
}

export default Login