export const availableServices = [
    { label: "Threading", value: "Threading" },
    { label: "Brazilian Wax", value: "Brazilian Wax" },
    { label: "Waxing - Honey", value: "Waxing - Honey" },
    { label: "Waxing - Rica", value: "Waxing - Rica" },
    { label: "Bleach", value: "Bleach" },
    { label: "D-Tan", value: "D-Tan" },
    { label: "Clean up", value: "Clean up" },
    { label: "Facial", value: "Facial" },
    { label: "Facial - O3+", value: "Facial - O3+" },
    { label: "Hair cut", value: "Hair cut" },
    { label: "Hair Wash", value: "Hair Wash" },
    { label: "Ironing", value: "Ironing" },
    { label: "Hair Spa", value: "Hair Spa" },
    { label: "Hair - Root Touchup", value: "Hair - Root Touchup" },
    { label: "Hair - Global", value: "Hair - Global" },
    { label: "Hair - Blow Dry", value: "Hair - Blow Dry" },
    { label: "Manicure", value: "Manicure" },
    { label: "Pedicure", value: "Pedicure" },
    { label: "Makeup", value: "Makeup" },
    { label: "Hair - Bluetox Treatment", value: "Hair - Bluetox Treatment" },
    { label: "Head Massage", value: "Head Massage" },
    { label: "Dandruff Treatment", value: "Dandruff Treatment" },
    { label: "Body Massage", value: "Body Massage" },
    { label: "Body Polishing", value: "Body Polishing" },
    { label: "Mehendi", value: "Mehendi" },
    { label: "Underarms", value: "Underarms" },
    { label: "Hand massage", value: "Hand massage" },
    { label: "Foot massage", value: "Foot massage" },
    { label: "Upper lips", value: "Upper lips" },
    { label: "Other", value: "Other" }
];
export const societyOptions = [
    { label: "Kaizen", value: "Kaizen" },
    { label: "F - Residences", value: "F - Residences" },
    { label: "Konark", value: "Konark" },
    { label: "Kunal Aspiree", value: "Kunal Aspiree" },
    { label: "Narayani", value: "Narayani" },
    { label: "K - Square", value: "K - Square" },
    { label: "Aryan Empire", value: "Aryan Empire" },
    { label: "Chloris", value: "Chloris" },
    { label: "Aditya Breeze Park", value: "Aditya Breeze Park" },
    { label: "Sai Silicon Valley", value: "Sai Silicon Valley" },
    { label: "Lemon Blossom", value: "Lemon Blossom" },
    { label: "Sanskruti Homes", value: "Sanskruti Homes" },
    { label: "Miracle Glory", value: "Miracle Glory" },
    { label: "Yugal Drashila", value: "Yugal Drashila" },
    { label: "Youthville Hostel", value: "Youthville Hostel" },
    { label: "Anjaneya Apartment", value: "Anjaneya Apartment" },
    { label: "Nishigandh Apartment", value: "Nishigandh Apartment" },
    { label: "Devashish Apartment", value: "Devashish Apartment" },
    { label: "Destiny Hotel", value: "Destiny Hotel" },
    { label: "Sai Ankoor", value: "Sai Ankoor" },
    { label: "43 Drive", value: "43 Drive" },
    { label: "Majestique Signature Towers", value: "Majestique Signature Towers" },
    { label: "Elite Empire", value: "Elite Empire" },
    { label: "Bliss Avenue", value: "Bliss Avenue" },
    { label: "Royal Park", value: "Royal Park" },
    { label: "Madhuban", value: "Madhuban" },
    { label: "Sapphire Park", value: "Sapphire Park" },
    { label: "Kundan", value: "Kundan" },
    { label: "Aura Apartment", value: "Aura Apartment" },
    { label: "Chaitanya Platinum", value: "Chaitanya Platinum" },
    { label: "Park Xpress", value: "Park Xpress" },
    { label: "Golden Castle", value: "Golden Castle" },
    { label: "PG", value: "PG" },
    { label: "Walk-in", value: "Walk-in" },
    { label: "Other", value: "Other" }
];