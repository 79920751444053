import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export default function InfoCard({ title, symbol, number, comparisonNumber, selectedMonthName, prevMonthName }) {
  return (
    <>
      <Card sx={{ height: 200, backgroundColor: "black" }}>
        <CardContent sx={{ backgroundColor: "black" }}>
          <Typography gutterBottom variant="h7" color="secondary" >
            {title}
          </Typography>
          <Divider color="white" />
          <Grid container>
            <Grid item xs={12}>
              <Typography gutterBottom variant="p" color="white" >
                {selectedMonthName}
              </Typography>
              <Typography variant="h6" component="div" color="white">
                {number}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider color="white" />
              <Typography gutterBottom variant="h7" color="gray" >
                {prevMonthName}
              </Typography>
              <Typography variant="h6" component="div" color="gray">
                {comparisonNumber}
              </Typography>
            </Grid>
          </Grid>

        </CardContent>
      </Card>
    </>
  );
}
