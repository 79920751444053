import React, { useState } from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function CollapseComponent({ children, title,defaultOpen }) {
  const [open, setOpen] = useState(defaultOpen);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={handleToggle} aria-expanded={open}>
          {open ? <ExpandLessIcon style={{ color: 'white' }} /> : <ExpandMoreIcon style={{ color: 'white' }} />}
        </IconButton>
        <Typography variant="h6" style={{color: 'white', marginRight: '10px' }}>{title}</Typography>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
}

export default CollapseComponent;
