import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
function TableCaption({ children }) {
    return (
        <Typography variant="h6" align="center" color="secondary" gutterBottom>
            {children}
        </Typography>
    );
}
function ServiceTable({ cmServiceCountData, pmServiceCountData }) {
    React.useEffect(() => {

    }, [])
    const getServiceCountFromKey = (data,key)=>{
        let count = 0;
        const filteredObject = data.find((element)=>{
            return element.name === key;
        })
        if(filteredObject){
            count = filteredObject.count;
        }
        return count;
    }
    return (
        <TableContainer component={Paper} sx={{ backgroundColor: "black", height:"300px" }}>
            <Divider color='white'></Divider>
            <Table aria-label="simple table">
                <TableHead >
                    <TableRow>
                        <TableCell align="center" ><Typography variant='h7' color={"secondary"}>Sr No</Typography></TableCell>
                        <TableCell align="center" ><Typography variant='h7' color={"secondary"}>Service </Typography></TableCell>
                        <TableCell align="center" ><Typography variant='h7' color={"secondary"}>Selected Month Count</Typography></TableCell>
                        <TableCell align="center" ><Typography variant='h7' color={"secondary"}>Previous Month Count</Typography></TableCell>
                        {/* <TableCell align="center" ><Typography variant='h7' color={"secondary"}>Bill</Typography></TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cmServiceCountData && pmServiceCountData && cmServiceCountData.map((row, index) => (
                        <TableRow >
                            <TableCell align="center" sx={{ color: "white" }}>{index + 1}</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>{row.name}</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>{row.count}</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>{getServiceCountFromKey(pmServiceCountData,row.name)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

}

export default ServiceTable